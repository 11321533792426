import { Route, Routes } from 'react-router-dom';
import React, { lazy, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { storeLogin } from './stores/storeLogin';

const ReportCreationPage = lazy(() => import('./auth/pages/report-creation'));
const UserManagementPage = lazy(() => import('./auth/pages/admin'));
const FormularioLogin = lazy(() => import('./auth/components/FormularioLogin'));
const LayoutInsideApp = lazy(() => import('./Layouts/LayoutInsideApp'));
const CheckUserAuth = lazy(() => import('./auth/components/CheckUserAuth'));

export const AppRoutes = observer(() => {
  useEffect(() => {
    storeLogin.setUserInfoAndCheck();
  }, []);

  return (
    <Routes>
      {!storeLogin.isAuthenticated ? (
        <Route path="/" element={<FormularioLogin />} />
      ) : (
        <Route path="/*" element={<CheckUserAuth />}>
          <Route element={<LayoutInsideApp />}>
            <Route index element={<ReportCreationPage />} />
            <Route path="admin" element={<UserManagementPage />} />
          </Route>
        </Route>
      )}
    </Routes>
  );
});
