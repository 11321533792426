import { message, Modal } from 'antd';
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

class HttpHelper {
  private static instance: HttpHelper;
  private axiosInstance: AxiosInstance;
  // BASE_URL: string = process.env.REACT_APP_API_URL;

  // BASE_URL = 'http://127.0.0.1:8000';
  // BASE_URL = 'http://15.228.91.14/api';
  BASE_URL = 'https://vallendspace.sigmageotecnologias.com.br/api';

  BASE_URL_AUTH = 'https://pdjango.sigmageotecnologias.com.br/';
  // BASE_URL_AUTH = 'http://127.0.0.1:3030/';

  S3_URL = 'https://tomobi4.s3.sa-east-1.amazonaws.com/';
  GEOSERVER_URL = 'https://tomobi4.sigmageotecnologias.com.br';

  private constructor() {
    this.axiosInstance = axios.create();
  }

  public static getInstance(): HttpHelper {
    if (!HttpHelper.instance) {
      HttpHelper.instance = new HttpHelper();
    }
    const token = localStorage.getItem('access');
    if (token) {
      HttpHelper.instance.setAuthorizationHeader(token);
    } else {
      HttpHelper.instance.deleteAuthHeader();
    }

    return HttpHelper.instance;
  }

  public getSSEUrl(endpoint: string): string {
    return `${this.BASE_URL}${endpoint}`;
  }

  private setAuthorizationHeader(token: string): void {
    this.axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  }

  private deleteAuthHeader(): void {
    delete this.axiosInstance.defaults.headers.Authorization;
  }

  public async get<T>(url: string, config?: any): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.get<T>(
        url,
        config
      );
      return response.data;
    } catch (error) {
      this.handleHttpError(error as AxiosError);
      throw error;
    }
  }

  public async post<T>(url: string, data?: any, config?: any): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.post<T>(
        url,
        data,
        config
      );
      return response.data;
    } catch (error) {
      this.handleHttpError(error as AxiosError);
      throw error;
    }
  }

  public async postFormData<T>(
    url: string,
    formData: FormData,
    config?: any
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.post<T>(
        url,
        formData,
        {
          ...config,
          headers: {
            ...config?.headers,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      this.handleHttpError(error as AxiosError);
      throw error;
    }
  }

  public async put<T>(url: string, data?: any, config?: any): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.put<T>(
        url,
        data,
        config
      );
      return response.data;
    } catch (error) {
      this.handleHttpError(error as AxiosError);
      throw error;
    }
  }

  public async delete<T>(url: string, config?: any): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.delete<T>(
        url,
        config
      );
      return response.data;
    } catch (error) {
      this.handleHttpError(error as AxiosError);
      throw error;
    }
  }

  private handleHttpError(error: AxiosError): void {
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Request:', error.request);
    } else {
      console.error('Error:', error.message);
    }
    console.error('Config:', error.config);
  }
}

export default HttpHelper;
